import React, { Suspense, lazy } from "react";
const Nav = lazy(() => import("./Nav"));
const Footer = lazy(() => import("./sections/Footer"));
const Modal = lazy(() => import("./sections/Modal"));
const SVG_Section = lazy(() => import("./sections/SVG_Section"));
const Section1 = lazy(() => import("./sections/Section1"));
const Section2 = lazy(() => import("./sections/Section2"));
const Section3 = lazy(() => import("./sections/Section3"));
const Section7 = lazy(() => import("./sections/Section7"));
const Section8 = lazy(() => import("./sections/Section8"));

export const Landing = ({ Mod, setMod }) => {
  return (
    <Suspense fallback={<div></div>}>
      <Nav />
      <Section1 />
      <Section2 />
      <Section3 />
      <SVG_Section />
      <Section7 />
      {/* <Section8
        {...{
          setMod,
          Mod,
        }}
      /> */}
      <Footer />
      {/* <Modal
        {...{
          setMod,
          Mod,
        }}
      /> */}
    </Suspense>
  );
};
