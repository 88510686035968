import { Suspense, lazy, useState } from "react";
import { Landing } from "./components/Landing";
import { useRoutes } from "react-router-dom";
import { Thankyou } from "./components/Thankyou";

// import { Nav } from "./components/Nav";
// import { Footer } from "./components/sections/Footer";
// import { Modal } from "./components/sections/Modal";
// import { SVG_Section } from "./components/sections/SVG_Section";
// import { Section1 } from "./components/sections/Section1";
// import { Section2 } from "./components/sections/Section2";
// import { Section3 } from "./components/sections/Section3";
// import { Section7 } from "./components/sections/Section7";
// import { Section8 } from "./components/sections/Section8";

const Nav = lazy(() => import("./components/Nav"));
const Footer = lazy(() => import("./components/sections/Footer"));
const Modal = lazy(() => import("./components/sections/Modal"));
const SVG_Section = lazy(() => import("./components/sections/SVG_Section"));
const Section1 = lazy(() => import("./components/sections/Section1"));
const Section2 = lazy(() => import("./components/sections/Section2"));
const Section3 = lazy(() => import("./components/sections/Section3"));
const Section7 = lazy(() => import("./components/sections/Section7"));
const Section8 = lazy(() => import("./components/sections/Section8"));

function App() {
  const [Mod, setMod] = useState(false);
  const routes = [
    {
      path: "/",
      element: <Landing {...{ Mod, setMod }} />,
    },
    {
      path: "/thank-you",
      element: <Thankyou />,
    },
  ];
  const route = useRoutes(routes);
  return <>{route}</>;
}

export default App;
