import React from "react";

export const Thankyou = () => {
  return (
    <div className="thankyou">
      <img
        className="img1"
        src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/MTPL/thankyoupage/Group%2047.svg"
      />
      <img
        className="img2"
        src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/MTPL/thankyoupage/Rectangle%2061.svg"
      />
      <img
        className="img3"
        src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/MTPL/thankyoupage/Group%2045.svg"
      />
    </div>
  );
};
